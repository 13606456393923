<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
          <v-form v-model="valid" ref="dateTimeForm" v-on:submit.prevent="">
            <v-text-field
                v-model="dateText"
                ref="date"
                :label="label"
                append-icon="mdi-calendar"
                outlined
                :dense="dense"
                :clearable="clearable"
                :hide-details="hideDetails"
                :hint="hint"
                :persistent-hint="persistentHint"
                v-bind="attrs"
                v-on="on"
                @click:append="menu = !menu"
                @click:clear="clear"
                :disabled="loading"
                :loading="loading"
                :rules="rules"
            ></v-text-field>
          </v-form>
        </template>
        <v-date-picker
            ref="picker"
            v-model="date"
            v-if="!loading"
            :type="type"
            :min="min"
            :max="max"
            no-title
            scrollable
            :allowed-dates="allowedDatesMethod"
            @change="$refs.menu.save(date)"
        ><!-- @click:date="$refs.menu.save(date)" -->
            <v-btn v-if="clearable" text block @click="clear">Clear</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
const DateRegex = /^(\d{4}-(?:0[1-9]|1[0-2])-+(?:0[1-9]|[1-2][0-9]|3[0-1]))$/i;
const DateRegexMonth = /^(\d{4}-(0[1-9]|1[0-2]))$/;
export default {
    name: 'DatePicker',
    data: () => ({
        date: null,
        menu: false,
        dateText: '',
        rules: [],
        valid: null,
      lastValidWatchDateText:null,
    }),
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Date',
        },
        type:{
          type:String,
          default: 'date'
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        persistentHint: {
            type: Boolean,
            default: false,
        },
        hint: {
            type: String,
            default: null,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        futureOnly: {
            type: Boolean,
            default: false,
        },
        activePicker:{
            type: String,
            default:null,
        },
        min:{
          type: String,
          default:null,
        },
        max:{
          type: String,
          default:null,
        },
      /**
       * Allow us to specify validation rules to be added.
       * These passed rules are appended to the rules already applied within this component.
       */
        rulesIn:{
          type: Array,
          default: function( ){
            return [];
          }
        },

      /**
       * list of dates that the user is allowed to select from.
       * In the format YYYY-MM-DD or YYYY-MM (for month picker)
       * ER [2023-11-29, 2023-12-02]
       * if null all dates are allowed
       */
        allowedDates:{
          type: Array,
          default: function( ){
            return null;
          }
        }

    },
    methods: {
      clear: function() {
        this.date = null;
        this.dateText = '';
      },
      allowedDatesMethod: function(date){
        if(this.allowedDates == null){
          return true;
        }

        if(this.allowedDates.includes(date)){
          return true;
        }

        return false;
      }

    },

    watch: {
        date: function (value) {
            if (!!value && value !== this.dateText){
              this.dateText = value;
            }
          /**
           * Note that is transferring one valid value to another valid value
           * the watcher on the valid value does not fire.
           * So we send the value to the input here.
           */
          if(this.valid){
              this.$emit('input', this.dateText);
            }
        },
        valid: function (value) {
          this.lastValidWatchDateText = this.dateText;
          if(!value){
            this.$emit('input', null);
          }else{
            this.$emit('input', this.dateText);
          }
        },

        value: function (value) {
          //Useful is the value is set in the parent. We copy over to the dateText input.
          if(value != null || (this.dateText != this.lastValidWatchDateText)){
            this.dateText = value;
          }
        },
        dateText: function (value) {
          if(this.type == 'month'){
            let date =
              DateRegexMonth.test(
                value
              )
                ? value
                : null;
            this.date = date;
          }else{
            let date =
              DateRegex.test(
                value
              )
                ? value
                : null;
            this.date = date;
          }
        },
        menu (val) {
          val && setTimeout(() => (this.$refs.picker.activePicker = this.activePicker))
        },
    },

    created() {
        if(this.type == 'month'){
          this.rules.push(
            (v) => !v || DateRegexMonth.test(v) || 'Enter date in YYYY-MM',
          );
        }else{
          this.rules.push(
            (v) => !v || DateRegex.test(v) || 'Enter date in YYYY-MM-DD',
          );
        }
        if (this.required) this.rules.push((v) => !!v || 'Required');
        if (this.futureOnly)
          if(this.type == 'month'){
            this.rules.push(
              (v) =>
                (DateRegexMonth.test(v) && new Date(v) > new Date()) ||
                'Must be in the future'
            );
          }else{
            this.rules.push(
              // (v) =>
              //   (DateRegex.test(v) ? new Date(v).getDate() > new Date().getDate(): true) ||
              //   'Must be in the future'
              (v) =>
                (DateRegex.test(v) && new Date(v) > new Date()) ||
                'Must be in the future'
            );
          }
        if(this.rulesIn.length > 0){
            this.rules = [...this.rules,...this.rulesIn];
        }
        if (this.value) {
            this.dateText = this.value;
        }
    },
};
</script>
