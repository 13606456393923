<template>
    <v-tooltip bottom :disabled="hideTooltip" open-delay="200">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <span :class="textClass" v-if="!addGst">{{ value | currency }} <v-icon v-if="warningIcon" :class="textClass">mdi-alert</v-icon></span>
                <span :class="textClass" v-if="addGst">{{ (value * 1.1) | currency }} <v-icon v-if="warningIcon" :class="textClass">mdi-alert</v-icon></span>
            </span>
        </template>
        ${{ value }}<span v-if="addGst"> excluding GST</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'Currency',
    filters: {
        currency: function (value) {
            if (value) {
              let prefix = '';
              let workingValue = value;
              if(value < 0){
                workingValue = value*-1;
                prefix = '-';
              }

                return prefix+(
                    '$' +
                    workingValue
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                );
            }
            return '$0.00';
        },
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        addGst: {
            type: Boolean,
            default: false,
        },
        hideTooltip: {
            type: Boolean,
            default: false,
        },
        textClass:{
          type: String,
          default: ''
        },
        warningIcon:{
          type: Boolean,
          default:false
        }
    },
};
</script>
